import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { NumericInput } from '@class101/ui';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "numericinput"
    }}>{`NumericInput`}</h1>
    <p>{`NumericInput 컴포넌트입니다.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "/form-inputs/input#props"
        }}>{`Input Props`}</a>{`를 상속받습니다.`}</p>
      <p parentName="blockquote"><inlineCode parentName="p">{`size`}</inlineCode>{`는 사용이 불가능합니다.`}</p>
    </blockquote>
    <h3 {...{
      "id": "additional-props"
    }}>{`Additional Props`}</h3>
    <Props of={NumericInput} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<NumericInput label=\"수량\" />\n<br />\n<NumericInput label=\"수량\" disabled />\n<br />\n<NumericInput buttonPosition=\"side\" label=\"수량\" />\n<br />\n<NumericInput buttonPosition=\"side\" label=\"수량\" disabled />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      NumericInput,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <NumericInput label="수량" mdxType="NumericInput" />
  <br />
  <NumericInput label="수량" disabled mdxType="NumericInput" />
  <br />
  <NumericInput buttonPosition="side" label="수량" mdxType="NumericInput" />
  <br />
  <NumericInput buttonPosition="side" label="수량" disabled mdxType="NumericInput" />
    </Playground>
    <h2 {...{
      "id": "inline"
    }}>{`Inline`}</h2>
    <Playground __position={2} __code={'<NumericInput label=\"수량\" inline buttonPosition=\"side\" />\n<NumericInput label=\"나이\" inline />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      NumericInput,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <NumericInput label="수량" inline buttonPosition="side" mdxType="NumericInput" />
  <NumericInput label="나이" inline mdxType="NumericInput" />
    </Playground>
    <h2 {...{
      "id": "min-and-max"
    }}>{`Min and Max`}</h2>
    <Playground __position={3} __code={'<NumericInput label=\"수량\" maxValue={5} value={5} />\n<NumericInput label=\"나이\" minValue={-1} value={-1} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      NumericInput,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <NumericInput label="수량" maxValue={5} value={5} mdxType="NumericInput" />
  <NumericInput label="나이" minValue={-1} value={-1} mdxType="NumericInput" />
    </Playground>
    <h2 {...{
      "id": "input-props"
    }}>{`Input Props`}</h2>
    <Playground __position={4} __code={'<NumericInput label=\"수량\" min={3} max={10} defaultValue={5} />\n<br />\n<NumericInput label=\"나이\" defaultValue={2000} step={1000} />\n<br />\n<NumericInput errorMessage=\"에러 메시지\" />\n<br />\n<NumericInput warnMessage=\"워닝 메시지\" />\n<br />\n<NumericInput allowMessage=\"그냥 메시지\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      NumericInput,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <NumericInput label="수량" min={3} max={10} defaultValue={5} mdxType="NumericInput" />
  <br />
  <NumericInput label="나이" defaultValue={2000} step={1000} mdxType="NumericInput" />
  <br />
  <NumericInput errorMessage="에러 메시지" mdxType="NumericInput" />
  <br />
  <NumericInput warnMessage="워닝 메시지" mdxType="NumericInput" />
  <br />
  <NumericInput allowMessage="그냥 메시지" mdxType="NumericInput" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      